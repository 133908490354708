/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import AddTopicDialog from '../components/chat/AddTopicDialog';
import SuccessSnackbar from '../components/shared/SuccessSnackbar';
import { userTypeKeywords } from '../constants/keywords';
import { addStoryItemTypes, firebaseDataType } from '../constants/other';
import { CHAT_ROOMS } from '../firebase/constants';
import { firebaseQueryOperators } from '../firebase/queryBuilder';
import { addStoryItem } from '../firebase/services/addServices';
import { getUnReadMessageCount } from '../firebase/services/getServices';
import { updateStoryItem } from '../firebase/services/updateServices';
import { useStoryItemListener } from '../hooks';
import useActivityStore from '../stores/ActivityStore';
import useChatStore from '../stores/ChatStore';
import useCourseStore from '../stores/CourseStore';
import useEssayStore from '../stores/EssayStore';
import useHonorStore from '../stores/HonorStore';
import useMyTeamStore from '../stores/MyTeamStore';
import useNicheStore from '../stores/NicheStore';
import useProfileStore from '../stores/ProfileStore';
import useStudentStore from '../stores/StudentStore';
import useTestStore from '../stores/TestStore';
import useApplicationStore from '../stores/ApplicationStore';
import useAdminStore from '../stores/AdminStore';
import { ASK_UNI_MAIL, teamUniversilyEmail } from '../constants/staticData';
import { INVITE_STATUS } from '../utils/utlityTypes';
import useTeamStore from '../stores/TeamStore';
import { getProfileByUserId } from '../firebase/services/user';
import { useShallow } from 'zustand/react/shallow';

const ChatWindowContainer = ({ profileUserId = '' }) => {
  const chatStore = useChatStore();
  const activityStore = useActivityStore(
    useShallow((state) => ({
      activities: state.activities,
    }))
  );
  const honorStore = useHonorStore(
    useShallow((state) => ({
      honors: state.honors,
    }))
  );
  const nicheStore = useNicheStore(
    useShallow((state) => ({
      niches: state.niches,
    }))
  );
  const courseStore = useCourseStore(
    useShallow((state) => ({
      courses: state.courses,
    }))
  );
  const profileStore = useProfileStore(
    useShallow((state) => ({
      profile: state.profile,
    }))
  );
  const testStore = useTestStore(
    useShallow((state) => ({ testTypes: state.testTypes }))
  );
  const myTeamStore = useMyTeamStore(
    useShallow((state) => ({ myTeam: state.myTeam }))
  );
  const allTeams = useTeamStore(
    useShallow((state) => ({ teams: state.teams }))
  );
  const studentStore = useStudentStore(
    useShallow((state) => ({
      student: state.student,
      selectedStudent: state.selectedStudent,
    }))
  );
  const essayStore = useEssayStore(
    useShallow((state) => ({ essays: state.essays }))
  );

  const { applications } = useApplicationStore(
    useShallow((state) => ({ applications: state.applications }))
  );
  const { collegeDetails } = useAdminStore(
    useShallow((state) => ({ collegeDetails: state.collegeDetails }))
  );

  const [selectedUtilityItems, setSelectedUtilityItems] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState({
    open: false,
    message: '',
  });
  const [topicData, setTopicData] = useState({});

  const isParent =
    profileStore.profile.profileType !== userTypeKeywords.STUDENT &&
    profileStore.profile.profileType !== userTypeKeywords.ADMINISTRATOR;

  const teamDataReviewers = !isParent
    ? myTeamStore.myTeam
        .filter(
          (teamMember) => teamMember?.isRegistered && teamMember?.isAccepted
        )
        .map(({ id, email, firstName, lastName }) => ({
          id: id,
          email,
          label: `${firstName} ${lastName}`,
        }))
    : studentStore.student
        .flat()
        .map(({ uid, email, firstName, lastName }) => ({
          id: uid,
          email: email,
          label: `${firstName} ${lastName}`,
          firstName,
          lastName,
        }));

  const {
    dataCount: chatDataCount,
    data: chatData,
    isFetching: isFetchingChat,
  } = useStoryItemListener(
    CHAT_ROOMS,
    firebaseDataType.STUDENT_DATA,
    [
      {
        property: 'chatParticipants',
        operator: firebaseQueryOperators.ARRAY_CONTAINS,
        value: profileUserId,
      },
    ],
    profileUserId
  );

  const utilitytTypeItems = [
    {
      id: 1,
      label: 'My Story',
    },
    {
      id: 2,
      label: 'Niche',
    },
    {
      id: 3,
      label: 'Activities',
    },
    {
      id: 4,
      label: 'Courses',
    },
    {
      id: 5,
      label: 'Honors',
    },
    {
      id: 6,
      label: 'Tests',
    },
    {
      id: 7,
      label: 'Essays',
    },
    {
      id: 8,
      label: 'Colleges',
    },
  ];

  const getChatDMforTeamMembers = async () => {
    const chatDMs = chatData?.filter((chatRoom) => !chatRoom?.isTopic);
    // we have to find the team members from all the teams instead of myTeamStore.myTeam
    const allTeamMembers = [];
    // first we have to store all the team members in one array
    allTeams.teams?.forEach((team) => {
      allTeamMembers.push(...team?.teamMembers);
    });
    const teamMemberChats = allTeamMembers
      .filter((teamMember) => teamMember.invite_status === INVITE_STATUS.JOINED)
      .map((teamMember) => {
        const findSimilarObjects = (chat) =>
          chat.chatParticipants.includes(teamMember?.uid);
        const filteredChat = chatDMs?.find(findSimilarObjects);
        return Object.assign({}, teamMember, {
          ...filteredChat,
          teamMemberExists: true,
          unreadMessages: 0,
        });
      })
      .filter((member) => member?.uid !== profileUserId);

    // declare a array named finalTeamMembers to store the team members
    const finalTeamMembers = [];
    // declaring allUiDs array to avoid duplicates by storing uid in it
    const allUiDs = [];
    // fetching the members data in parallel processing it will reduce fetching time
    const memberProfiles = await Promise.all(
      teamMemberChats.map(async (member) => {
          return await getProfileByUserId(member.uid);
      })
    );
    for(const memberProfile of memberProfiles) {
      if(!allUiDs.includes(memberProfile[0]?.uid)) {
        if(memberProfile?.length > 0) {
          const teamMemberDetails = teamMemberChats.find((teamMember) => teamMember.uid === memberProfile[0]?.uid);
          const { photoURL, firstName, lastName } = memberProfile[0];
          const memberDetails = {
            ...teamMemberDetails,
            photoURL,
            firstName,
            lastName,
          };
          finalTeamMembers.push(memberDetails);
          allUiDs.push(memberDetails?.uid);
        }
      }
    }

    let updateMembers = [];

    if (
      profileStore?.profile?.profileType !== userTypeKeywords.STUDENT &&
      myTeamStore.myTeam?.length > 0 &&
      myTeamStore.myTeam.some(
        (teamMember) =>
          teamMember.isRegistered &&
          [ASK_UNI_MAIL, teamUniversilyEmail].includes(teamMember?.email)
      )
    ) {
      const AI_USER = myTeamStore.myTeam
        .filter(
          (teamMember) =>
            teamMember.isRegistered &&
            [ASK_UNI_MAIL, teamUniversilyEmail].includes(teamMember?.email)
        )
        ?.map((teamMember) => {
          const findSimilarObjects = (chat) =>
            chat.chatParticipants.includes(teamMember?.profileUserId);
          const filteredChat = chatDMs?.find(findSimilarObjects);
          return Object.assign({}, teamMember, {
            ...filteredChat,
            teamMemberExists: true,
            unreadMessages: 0,
          });
        });
      updateMembers = AI_USER;
    }

    return [...finalTeamMembers, ...updateMembers];
  };

  //const teamMemberChats = myTeamStore.myTeam
  //  .filter((teamMember) => teamMember.isRegistered)
  //  .map((teamMember) => {
  //    const findSimilarObjects = (chat) =>
  //      chat.chatParticipants.includes(teamMember?.profileUserId);
  //    const filteredChat = chatDMs?.find(findSimilarObjects);
  //    return Object.assign(
  //      {},
  //      {
  //        ...filteredChat,
  //        unreadMessages: 0,
  //      },
  //      teamMember
  //    );
  //  });

  const getUnReadCount = async (chatTopics) => {
    return chatTopics?.length > 0
      ? await Promise.all(
          chatTopics?.map(async (chatRoom) => {
            return await getUnReadMessageCount(
              chatRoom?.id,
              profileStore.profile?.uid
            ).then((result) => {
              return {
                ...chatRoom,
                unreadMessages: result,
              };
            });
          })
        )
      : [];
  };

  const getChatRoomInfo = (chatDMs) => {
    const teamMemberChats = chatDMs?.map((chats) => {
      let newChatRoomObj = {};
      const teamMemberInChat =
        profileStore.profile?.profileType === userTypeKeywords.STUDENT
          ? myTeamStore.myTeam?.find((teamMember) =>
              chats.chatParticipants?.includes(teamMember?.profileUserId)
            )
          : // eslint-disable-next-line array-callback-return
            studentStore.student.flat().map((teamMember) => {
              chats.chatParticipants?.includes(teamMember?.uid);
            });
      if (teamMemberInChat || chats?.chatParticipants?.length === 1) {
        newChatRoomObj = {
          ...chats,
          teamMemberExists: true,
        };
      } else {
        newChatRoomObj = {
          ...chats,
          teamMemberExists: false,
        };
      }
      return newChatRoomObj;
    });
    return teamMemberChats;
  };

  useEffect(() => {
    // we have to find the team members from all the teams instead
    getChatDMforTeamMembers()?.then((chatResult) => {
      getUnReadCount(chatResult).then((result) => {
        chatStore.setChatRoomsDM(result);
        if (result.length > 0) {
          chatStore.setIsFetchingChat(false);
        }
      });
    });
    //eslint-disable-next-line
  }, [chatData, studentStore.student, allTeams.teams]);

  useEffect(() => {
    let utilityItems = [];
    switch (topicData?.utilityType?.toLowerCase()) {
      case 'activities': {
        utilityItems = activityStore.activities?.map((activity) => ({
          id: activity?.id,
          label: activity?.activityName,
        }));
        break;
      }
      case 'honors': {
        utilityItems = honorStore.honors?.map((honor) => ({
          id: honor?.id,
          label: honor?.honorName,
        }));
        break;
      }
      case 'tests': {
        utilityItems = testStore.testTypes.map((test) => ({
          id: test?.id,
          label: test?.type,
        }));
        break;
      }
      case 'niche': {
        utilityItems = nicheStore.niches?.map((niche) => ({
          id: niche?.id,
          label: niche?.nicheName,
        }));
        break;
      }
      case 'courses': {
        utilityItems = courseStore.courses?.map((course) => ({
          id: course?.id,
          label: course?.courseName,
        }));
        break;
      }
      case 'my story': {
        break;
      }
      case 'colleges': {
        utilityItems = applications?.map((application) => ({
          id: application?.id,
          label: collegeDetails.find(
            (college) => college?.id === application?.collegeId
          )?.collegeName,
        }));
        break;
      }
      case 'essays': {
        utilityItems = essayStore.essays?.map((essay) => ({
          id: essay?.id,
          label: essay?.essayName,
        }));
        break;
      }
      default: {
        break;
      }
    }
    setSelectedUtilityItems(utilityItems);
    //eslint-disable-next-line
  }, [topicData?.utilityType]);

  const addNewTopic = async () => {
    if (chatStore.isAddingTopic) {
      chatStore.setIsChatOpen(false);
      let reviewerDetails = {};
      if (topicData?.topicName?.trim() !== '') {
        if (topicData.selectedTeamMembers) {
          if (!isParent) {
            reviewerDetails = myTeamStore.myTeam.find(
              (member) =>
                member?.email === topicData?.selectedTeamMembers?.email
            );
          } else {
            reviewerDetails = {
              profileUserId: studentStore?.selectedStudent?.uid
                ? studentStore.selectedStudent?.uid
                : topicData?.selectedTeamMembers?.id,
            };
          }
        }
        const id = await addStoryItem(
          {
            isTopic: true,
            archivedBy: [],
            chatParticipants: reviewerDetails?.profileUserId
              ? [reviewerDetails.profileUserId, profileStore.profile?.uid]
              : [profileStore.profile?.uid],
            topicName: topicData?.topicName,
            utilityId: topicData?.utilityItem,
            topicType: topicData?.utilityType,
          },
          addStoryItemTypes.CHAT_ROOM
        );
        chatStore.setIsChatWindowOpen(true);
        chatStore.setOpenChatType('topic');
        chatStore.setOpenChatId(id);
        setSnackbarMessage({
          open: true,
          message: 'The topic has been added',
        });
      }
    } else if (chatStore.isEditingTopic) {
      let reviewerDetails = {};
      if (topicData.selectedTeamMembers) {
        if (!isParent) {
          reviewerDetails = myTeamStore.myTeam.find(
            (member) => member?.email === topicData?.selectedTeamMembers?.email
          );
        } else {
          reviewerDetails = {
            profileUserId: topicData?.selectedTeamMembers?.id,
          };
        }
      }
      //const reviewerDetails = myTeamStore.myTeam.find(
      //  (member) => member?.email === topicData?.selectedTeamMembers?.email
      //);
      await updateStoryItem(
        {
          id: chatStore?.openChatId,
          chatParticipants: reviewerDetails?.profileUserId
            ? [reviewerDetails.profileUserId, profileStore.profile?.uid]
            : [profileStore.profile?.uid],
          topicName: topicData?.topicName,
        },
        addStoryItemTypes.CHAT_ROOM
      );
      chatStore.setIsEditingTopic(false);
      chatStore.setIsChatOpen(false);
      chatStore.setOpenChatType(null);
      chatStore.setOpenChatId(null);
      setSnackbarMessage({
        open: true,
        message: 'The topic was edited successfully',
      });
    }
  };

  const getTopicDetails = () => {
    if (chatStore.isEditingTopic) {
      const id = chatStore.openChatId;
      const chatRoomDetails = chatStore.chatRoomsTopics?.find(
        (chatRoom) => chatRoom.id === id
      );
      const teamMemberId = chatRoomDetails?.chatParticipants.find(
        (chatMember) => chatMember !== profileStore.profile?.uid
      );
      const teamMember = !isParent
        ? myTeamStore.myTeam?.find(
            (teamMember) => teamMember.profileUserId === teamMemberId
          )
        : teamDataReviewers?.find(
            (teamMember) => teamMember.id === teamMemberId
          );
      const result = {
        topicName: chatRoomDetails?.topicName,
        selectedTeamMembers: {
          id: teamMember?.id,
          email: teamMember?.email,
          label: `${teamMember?.firstName} ${teamMember?.lastName}`,
        },
      };
      return result;
    } else if (chatStore.isAddingTopic) {
      const url = window.location.pathname;
      const utilInURL = url.split('/');
      let urlType = '';
      if (utilInURL[2]) {
        urlType =
          utilInURL[2]?.charAt(0).toUpperCase() + utilInURL[2]?.slice(1);
      } else {
        if (utilInURL[1] === 'myEssay') {
          urlType = 'Essays';
        } else if (utilInURL[1] === 'myColleges' || utilInURL[1] === 'myHub') {
          urlType = chatStore.applicationPageUtil || 'Colleges';
        }
      }
      const utilityType = utilitytTypeItems.find(
        (util) => util.label === urlType
      );
      return {
        utilityType: utilityType?.label,
        utilityItem: chatStore.utilityId || '',
      };
    }
    return {};
  };

  const onTopicDataChange = (formData) => {
    setTopicData(formData);
  };

  const onCancel = () => {
    chatStore.setApplicationPageUtil(null);
    if (chatStore.isAddingTopic) chatStore.setIsAddingTopic(false);
    else chatStore.setIsEditingTopic(false);
    if (!chatStore.isFilterTopic) {
      chatStore.setUtilityId(null);
    }
  };

  return (
    <>
      <SuccessSnackbar
        open={snackbarMessage.open}
        message={snackbarMessage.message}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarMessage({
            open: false,
            message: '',
          });
        }}
      />
      <AddTopicDialog
        continueText={chatStore.isEditingTopic ? 'Update' : 'Add'}
        onCancel={onCancel}
        onChange={onTopicDataChange}
        isParent={isParent}
        onContinue={() => {
          addNewTopic();
          chatStore.setIsAddingTopic(false);
          if (!chatStore.isFilterTopic) {
            chatStore.setUtilityId(null);
          }
        }}
        selectedStudentID={studentStore?.selectedStudent?.uid}
        selectedStudentName={`${studentStore?.selectedStudent?.firstName} ${studentStore?.selectedStudent?.lastName}`}
        selectedUtilityItems={selectedUtilityItems}
        utilitytTypeItems={utilitytTypeItems}
        isEdit={chatStore.isEditingTopic}
        open={chatStore.isAddingTopic || chatStore.isEditingTopic}
        teamMemberData={teamDataReviewers}
        existingTopicData={getTopicDetails()}
      />
    </>
  );
};

export default ChatWindowContainer;
